.articleList {
    padding: 0;
}

.articleItem {
}

.articleTitle {
}

.articleTitle a {
    color: black;
    text-decoration: none;
}

.articleTitle a:hover {
    color: gray;
}

.articleTags, .articlePosted {
    color: gray;
    font-size: 0.8rem;
    padding: 0;
}


.tagButton {
    font-size: 70%;
}

.tagButtonSelected:hover {
    cursor: pointer;
}

.timeline {
    margin-top: 20px;
}

.timelineYear {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.5rem;
}

.timelineDate {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 0.8rem;
    position: relative;
    top: -1rem;
}

.timelineList {
    list-style: none;
    margin-left: 20px;
}

.timelineContent {
    display: inline-block;
    line-height: 1rem;
    margin-left: 20px;
}

.timelineContent>a {
    color: #333;
    text-decoration: none;
}

.timelineContent>a:hover {
    text-decoration: underline;
    color: gray;
}

/********************************
 * Article Content
 ********************************/
.articleContent pre {
    overflow: auto;
    padding: 10px;
    font-size: 0.8em;
    line-height: 1.3;
    background-color: #EEEEFF;
    -webkit-border-radius: 6px 6px 6px 6px;
    -moz-border-radius: 6px 6px 6px 6px;
    border-radius: 6px 6px 6px 6px;
    margin-bottom: 10px;
}

.articleContent blockquote {
    border-left: 5px solid lightgray;
    padding-left: 10px;
}

.articleContent table {
    /* Remove spacing between table cells (from Normalize.css) */
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid #cbcbcb;
}

.articleContent table caption {
    color: #000;
    font: italic 85%/1 arial, sans-serif;
    padding: 1em 0;
    text-align: center;
}

.articleContent table td,
.articleContent table th {
    border-left: 1px solid #cbcbcb;/*  inner column border */
    border-width: 0 0 0 1px;
    font-size: inherit;
    margin: 0;
    overflow: visible; /*to make ths where the title is really long work*/
    padding: 0.5em 1em; /* cell padding */
}

.articleContent table thead {
    background-color: #e0e0e0;
    color: #000;
    text-align: left;
    vertical-align: bottom;
}

/*
striping:
   even - #fff (white)
   odd  - #f2f2f2 (light gray)
*/
.articleContent table td {
    background-color: transparent;
    border-bottom: 1px solid #cbcbcb;
}

/* nth-child selector for modern browsers */
.articleContent table tr:nth-child(2n-1) td {
    background-color: #f2f2f2;
}

/* BORDERED TABLES */
.articleContent table tbody > tr:last-child > td {
    border-bottom-width: 0;
}

.articleContent img {
    max-width: 100%;
    height: auto;
    display: block;
}

html, button, input, select, textarea, 
.puge-g [class *= "pure-u"] {
    font-family: 'Lato', sans-serif;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: #333;
}

a {
    color: blue;
}

h4 {
    margin-bottom: 0.1em;
}

.container {
    max-width: 700px;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
}

.navigation {
    font-size: 1.5vh;
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid gray;
}

.footer {
    padding: 15px;
    text-align: center;
    border-top: 1px solid gray;
    color: gray;
    font-size: 0.8rem;
}

.list {
    padding: 0;
}

.listItem {
    list-style: none;
    line-height: 1rem;
}

.title {
}

.tags {
    color: gray;
    font-size: 0.8rem;
    padding: 0;
}
